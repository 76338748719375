import React, { useState, createContext, useEffect } from "react";

//Drawer state  context

export type IDrawer = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

//custom hook to handle MyAccount menu state
export const MyAccountDrawerState = (newOpenValue: boolean): IDrawer => {
  const [myAccountDrawerOpen, setMyAccountDrawerOpen] = useState(false);

  useEffect(() => {
    setMyAccountDrawerOpen(newOpenValue);
  }, [newOpenValue]);

  return [myAccountDrawerOpen, setMyAccountDrawerOpen];
  };

//context for the MyAccount menu state
export const MyAccountBtnContext= createContext<IDrawer>(undefined!);


//providing context to rest of app
interface Props{
  children: React.ReactNode;
}

const MyAccountBtnProvider = ({children}: Props) => {
  const [myAccountDrawerOpen, setMyAccountDrawerOpen]=MyAccountDrawerState(false);
  return (
    <MyAccountBtnContext.Provider value={[myAccountDrawerOpen, setMyAccountDrawerOpen]}>
        {children}
    </MyAccountBtnContext.Provider>
  );
};

//enabling the use of this context provider throughout the app
const useMyAccountBtnContext = () => {
  return React.useContext(MyAccountBtnContext);
};

export { MyAccountBtnProvider, useMyAccountBtnContext };
