import React, { useContext, useState } from 'react';
import { NavDataContext } from '../../../../../MFRMHeader';
import { MfrmBadge } from '../../L1/badge';
import charCount from '../../../../../util/char-count';

interface IPrimaryCategoryProps {
  categories: {
    productMenuCategory: any;
  };
}

export const PrimarySubcategoryMenu: React.FC<IPrimaryCategoryProps> = ({
  categories
}) => {
  const { bedhubDataSource } = useContext(NavDataContext);
  const productMenuCategoryData = bedhubDataSource ? categories : categories.productMenuCategory;

  return (
    <>
      {/* <div>PrimarySubcategoryMenu</div> */}
      {
        productMenuCategoryData && productMenuCategoryData.map((category: any) => {
          const [isShowMore, setIsShowMore] = useState(true);
          const onHandleMaximum = (): void => {
            setIsShowMore(!isShowMore);
          };

          // const categoryMaximum = bedhubDataSource ? : category.maximum
          const maximumCount = bedhubDataSource ? category?.accordion?.maximum : category?.maximum ? category?.maximum : 0;
          const categoryListCount = category?.items.length;
          const categoryItems = (maximumCount <= categoryListCount && isShowMore && maximumCount !== 0) ? category?.items.slice(0, maximumCount) : category.items;

          return (
            <div className="subcategory-menu" key={category.label}>
              {category.label && <div className="subcategory-title">
                <p>{category.label}</p>
              </div>}
              {
                category && categoryItems.map((item: any) => (
                  <a
                    key={item.label}
                    className="subcategory-primary-menu-item"
                    href={bedhubDataSource ? item.url : item.url.destinationUrl}
                    data-testid={item.label}
                  >
                    <img
                      className="subcategory-primary-menu-item-icon"
                      src={bedhubDataSource ? item.image.url : item.image?.src || ''}
                      width={40}
                      height={40}
                      alt={item.label}
                    />
                     <p className='subcategory-primary-menu-item-text'>{item.badge && item.badge.label ? charCount(item.label, 22) : item.label } {item.badge && item.badge.label && <MfrmBadge badgeData={item.badge} /> || null}</p>
                  </a>
                ))
              }
              {categoryListCount >= maximumCount && maximumCount !== 0 &&
                <p
                  className='subcategory-primary-menu-item-maximum'
                  tabIndex={0}
                  data-testid={isShowMore ? 'MoreIcon' : 'LessIcon'}
                  onClick={() => onHandleMaximum()}
                  onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        onHandleMaximum();
                      }
                  }}
                >
                  {isShowMore ? 'More' : 'Less'}
                </p>
              }
            </div>
          );
        })
      }
    </>
  );
};
