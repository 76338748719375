import React, { useRef } from 'react';
import Input from 'mfcl/Input';
import Button from 'mfcl/Button';
import LocationIcon from '../../../../icons/LocationIcon';

const Search: React.FC<any> = (props) => {
    const textInput = useRef<HTMLInputElement | null>(null);
    const onHandleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue: string = e.target.value;
        props.setSearchTerm(inputValue);
    };

    const onHandleSearchSubmit = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
        const { searchTerm } = props;
        if (searchTerm === '' || !re.test(searchTerm)) {
            props.setIsError(true);
        } else {
            // @ts-ignore
            if ('utag' in window) {
                const pageURL = window && window.location.pathname;
                // @ts-ignore
                utag.link({
                    event: "store-locator-slideout-search",
                    event_category: "store locator slideout",
                    event_action: "search click",
                    event_label: `from: ${pageURL}`,
                    event_noninteraction: "false"
                });
            }
            textInput?.current?.focus();
            props.onGetSociLocations(props.lookupRadius, props.maxNoOfLocations, searchTerm);
            props.setIsError(false);
        }
    };

    return (
        <form className="search-location-wrapper" onSubmit={onHandleSearchSubmit}>
            <div className="usecurrent-location-wrapper" tabIndex={0}>
                <LocationIcon />
                <span onClick={props.onHandleCurrentLocations} className='use-current-location-link'>Use current location</span>
            </div>

            <div className="search-location-form">
                <Input
                    wrapperClass="zipcode-input-wrapper"
                    className="zipcode-input"
                    value={props.searchTerm}
                    label="Zip Code"
                    error={props.isError}
                    size="md"
                    maxLength={5}
                    ref={textInput}
                    onChange={onHandleSearchTermChange}
                />
                <Button
                    type="submit"
                    className="search-location-button"
                    size="lg"
                >
                    Search
                </Button>
            </div>
        </form>
    );
};

export default Search;
