import React from 'react';
import Button from 'mfcl/Button';

const ShowAllStores: React.FC<any> = (props: any) => {
    const { enableShowAllStores, showAllStoresExternalLink } = props;
    if (enableShowAllStores){
        return (
            <div className="store-selector-drawer__footer">
                <Button
                    target={showAllStoresExternalLink?.openInNewTab ? '_blank' : undefined}
                    href={showAllStoresExternalLink?.linkUrl?.destinationUrl}
                    size="lg"
                    className='see-all-btn'
                >
                    {showAllStoresExternalLink?.linkText || 'See All Stores'}
                </Button>
            </div>

        );
    }
    return null;
};

export default ShowAllStores;
