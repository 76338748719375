import React from 'react';
import { useMainLinkContext } from '../../../../context/MainLinkContext';

export const BackToMainMenu = (changeSelectedLevel: any) => {
  const [selectedLink, setSelectedLink] = useMainLinkContext();
  return (
    <>
      <div className="desktop-back-button">
        <div className="back-icon">
          <img
            src='https://images-us-prod.cms.dynamics365commerce.ms/cms/api/fknscsckgq/imageFileData/MAgPew?pubver=1'
            alt='back-button'
            className='back-icon-img'
            onClick={() => {
              changeSelectedLevel.changeSelectedLevel('L1');
              setSelectedLink(`${selectedLink}_div`);
            }}
            onKeyDown={(e: {key: string}) => {
              if (e.key === 'Enter') {
                changeSelectedLevel.changeSelectedLevel('L1');
                setSelectedLink(`${selectedLink}_a`);
              }
            }}
            tabIndex={0}
            data-testid='ArrowBackIcon'
          />
          {/* <ChevronLeft width={24} height={24}/> */}
        </div>
      </div>

      <div
        className="mobile-back-button"
        data-testid='ArrowBackIcon'
        onClick={() => {
          changeSelectedLevel.changeSelectedLevel('L1');
          setSelectedLink(`${selectedLink}_div`);
        }}
        onKeyDown={(e: {key: string}) => {
          if (e.key === 'Enter') {
            changeSelectedLevel.changeSelectedLevel('L1');
            setSelectedLink(`${selectedLink}_a`);
          }
        }}
      >
        <div className="back-icon">
          <img
            src='https://images-us-prod.cms.dynamics365commerce.ms/cms/api/fknscsckgq/imageFileData/MAgPew?pubver=1'
            alt='back-button'
            className='back-icon-img'
            tabIndex={0}
          />
        </div>
        <p className='back-icon-text'>
          Back to Main Menu
        </p>
      </div>
    </>
  );
};