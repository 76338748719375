import React, { useContext } from 'react';
import { NavDataContext } from '../../../MFRMHeader';

export const CompanyLogo: React.FC<any> = () => {

  const { logoImage, logoLink } = useContext(NavDataContext);

  if (logoLink.linkUrl.destinationUrl) {
    return (
      <div className='mfrm-logo' tabIndex={0}
        onKeyDown={(e: { key: string }) => {
          if (e.key === 'Enter') {
            document.getElementById("mfrm-logo")?.click();
          }
        }}
      >
        <a
          id='mfrm-logo'
          tabIndex={-1}
          href={logoLink.linkUrl.destinationUrl}
          aria-label={logoLink.ariaLabel}
          target={logoLink.openInNewTab ? '_blank' : undefined}
          data-testid='MattressFirmLogo'
        >
          <img src={logoImage.src || ''} alt={logoImage.altText} height="33" width="240" className='logo-icon-img' />
        </a>
      </div>
    );
  }
  return (
    <div className='mfrm-logo'>
      <img src={logoImage.src || ''} alt={logoImage.altText} height="33" width="240" className='logo-icon-img' />
    </div>
  );
};

