import React, { useEffect } from 'react';
import HamburgerMenu from 'mfcl/Icons/HamburgerMenu';
import MenuWrapper from '../../menu/menu-components/MenuWrapper';
import { useHamburgerBtnContext } from '../../../context/HamburgerBtnContext';
import { useMenuLevelContext } from '../../../context/MenuLevelContext';
import { useMainLinkContext } from '../../../context/MainLinkContext';
import { trapFocus } from '../../../util/trap-focus';

export default function HamburgerMenuBtn() {
  //drawer state from context
  const [navDrawerOpen, setNavDrawerOpen] = useHamburgerBtnContext();
  const [selectedLevel] = useMenuLevelContext();
  const [selectedLink, setSelectedLink] = useMainLinkContext();
  const openNavDrawer = () => {
    setNavDrawerOpen(true);
  };

  const handleKeyDown = (e: { key: string, preventDefault: () => void }) => {
    if (e.key === 'Enter') {
      openNavDrawer();
      e.preventDefault();
    }
  };

  useEffect(() => {
    trapFocus('.navbar-drawer_header_close', '.navbar-drawer_body_link_secondary_container', '.navbar-drawer_body_link_main_container');
    const removedSpacesAndSpecialText = selectedLink.replace(/[^A-Z0-9]/ig, "_");
    const selectedL1MainLink = document.querySelector(`#navbar-drawer_body_link_main_item-${removedSpacesAndSpecialText}`) ?
      document.querySelector(`#navbar-drawer_body_link_main_item-${removedSpacesAndSpecialText}`) : undefined;
    if (selectedLevel === 'L1' && navDrawerOpen && selectedLink !== '' && selectedL1MainLink) {
      (selectedL1MainLink as HTMLElement).focus();
      setSelectedLink('');
    }
  }, [navDrawerOpen, selectedLevel]);

  return (
    <div className='hamburger-menu__wrapper'>
      <div className='hamburger-menu__btn' tabIndex={0} onKeyDown={handleKeyDown} onClick={openNavDrawer} data-testid='HamburgerMenu'>
        <HamburgerMenu width={16} height={12} /> <br /> <p className='hamburger-menu__btn-text'>Menu</p>
      </div>
      <MenuWrapper />
    </div>
  );
}