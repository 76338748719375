import React from 'react';
import StoreLineItem from './StoreLineItem';
import Loading from 'mfcl/Loading';

const Stores: React.FC<any> = (props) => {
    const { isLoading, locations, errorLocationsText } = props;
    const preferredStoreId = props.preferredStoreId();

    return (
        <div className="store-list-wrapper">
            {isLoading &&
                <div className='loader-wrapper'>
                    <Loading size="lg" />
                </div>
            }
            {locations?.map((location: any) => (
                <StoreLineItem
                    location={location}
                    key={location.id}
                    onHandleSetPreferredStore={props.setPreferredStore}
                    onHandleRemovePreferredStore={props.removePreferredStore}
                    preferredStoreId={preferredStoreId}
                />
            ))}
            {locations.length === 0 && !isLoading &&
                <div className="no-stores-wrapper">
                    <div className="no-stores-details">
                        <div dangerouslySetInnerHTML={{ __html: errorLocationsText }} />
                    </div>
                </div>
            }
        </div>
    );
};

export default Stores;