
import React from 'react';
import Button from 'mfcl/Button';
import dayjs from 'dayjs';

const StoreLineItem: React.FC<any> = (props) => {
  const { name, project_meta, bho, _distance, address1, address2, city, state, postalcode, website_url } = props.location;


  const isFranchise = JSON.parse(project_meta).Division === 'Franchise' || false;
  const projectMetaSoci = project_meta ? JSON.parse(project_meta) : '';
  const sociStore = projectMetaSoci ? projectMetaSoci['Corporate ID'] : '';
  const sociStoreID = sociStore && (sociStore?.length > 6 ? sociStore : `000000${sociStore}`?.slice(-6)) || '';
  const isPreferredStore = props.preferredStoreId === sociStoreID || false;
  const storeHours = JSON.parse(bho)?.[dayjs().day()];
  const opensAt = dayjs(`1/1/1 ${storeHours[0].slice(0, 2)}:${storeHours[0].slice(2)}`).format('hh:mm A');
  const closedAt = dayjs(`1/1/1 ${storeHours[1].slice(0, 2)}:${storeHours[1].slice(2)}`).format('hh:mm A');
  const isStoreOpen = (storeHours[0].slice(0, 2) < dayjs().hour() && storeHours[1].slice(0, 2) > dayjs().hour()) ? true : false;
  const pageURL = window && window.location.pathname;
  const onClickStoreDetails = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // @ts-ignore
    if ('utag' in window) {
      // @ts-ignore
      utag.link({
        event: "store-locator-slideout-store-details",
        event_category: "store locator slideout",
        event_action: "store details link click",
        event_label: `from: ${pageURL}`,
        event_noninteraction: 'false'
      });
    }
  };
  return (
    <>
      <div className={`${isFranchise ? 'store-line-item-wrapper franchise' : 'store-line-item-wrapper'}`}>
        <div>
          {isFranchise ? <span className='franchise-pill'>Franchise</span> : <></>}
          <span className="store-name">{name} </span>
        </div>

        <div className="store-distance">
          {bho && <span className='store-hours-time'>{isStoreOpen ? `Open until ${closedAt}` : `Open at ${opensAt}`}</span>}
          {_distance && <span className='store-hours-distance'>{_distance} mi</span>}
        </div>
        <div className="store-address">
          <address className='store-address-line'>
            {address1}<br />
            {address2}
            <p>{city && `${city},`} {state} {postalcode}</p>
          </address>
        </div>
        <div className="store-line-item-footer">
          {/* Todo: make my store functionalityis partial need to fix and need to add franchise check */}
          {!isFranchise &&
            isPreferredStore ? (
            <button
              type="button"
              className="my-store"
              onClick={() => props.onHandleRemovePreferredStore()}
            >
              My Store
            </button>)
            : !isFranchise && !isPreferredStore ?
              <Button
                btnType="secondary"
                size="md"
                className="make-my-store"
                onClick={() => props.onHandleSetPreferredStore(props.location)}
              >
                Make my store
              </Button>
              : <></>}
          <a
            className="store-details-link"
            target="_self"
            href={website_url}
            onClick={onClickStoreDetails}
            tabIndex={0}
          >
            Store details
          </a>
        </div>
      </div>
    </>
  );
};

export default StoreLineItem;
