import React from 'react';
import CartIcon from './CartIcon';
import { UserProfile } from './UserProfile';
import { StoreLocator } from './StoreLocator';


export const UtilityIcon: React.FC<any> = (props) => {

    //dynamics data variables
    const { ariaLabel, iconUrl, mobile, type, desktop, url } = props.iconData;
    const { cartIcon } = props.headerData.slots;

    const handleKeyDown = (e: { key: string }) => {
        if (e.key === 'Enter') {
            window.location.href = url;
        }
    };

    //!TODO make these all have different types rather than labels that can be changed
    const analyticsEventFire = (href: string) => {
        if (href?.includes('tel')) {
            if ('utag' in window) {
                // @ts-ignore
                utag.link({
                    event: "call-engagement",
                    event_category: "call",
                    event_noninteraction: "false",
                    event_type: "call_engagement",
                    event_action: "header click",
                    event_label: "call engagement"
                });
            }
        }
    };

    const hideIconOrNumber = (mobile: boolean, desktop: boolean) => {
        if (mobile) {
            return 'utility-icon icon-desktop-hide';
        }

        if (desktop) {
            return 'icon-mobile-hide';
        }

        return '';
    };

    const hideIcon = (mobile: boolean, desktop: boolean) => {
        if(!mobile && !desktop){
            return 'utility-icon mobile-hide desktop-hide ';
        }
        if (!mobile && desktop) {
            return 'utility-icon mobile-hide desktop-show ';
        }

        if (!desktop && mobile) {
            return ' utility-icon desktop-hide mobile-show ';
        }

        return 'mobile-show  desktop-show';
    };

    const getUtilityIcon = (type: string): JSX.Element => {
        switch (type) {
            case '':
                if (url?.includes('tel:')) {
                    return (
                        <div
                            key={ariaLabel}
                            data-type={type ?? 'generic'}
                            className={hideIconOrNumber(mobile, desktop)}
                            tabIndex={0}
                            onKeyDown={handleKeyDown}
                            onClick={() => analyticsEventFire(url)}
                        >
                            <a
                                href={url}
                                className='utility-icon__link'
                                tabIndex={-1}
                                data-testid={ariaLabel}
                            >
                                {
                                    mobile
                                    ? iconUrl && <img className='utility-icon__icon' src={iconUrl} alt={ariaLabel} />
                                    : <div className='utility-icon__icon phone-no'>{ariaLabel}</div>
                                }
                            </a>
                        </div>
                    );
                }
                return (
                    <div
                        key={ariaLabel}
                        data-type={type ?? 'generic'}
                        className={hideIcon(mobile, desktop)}
                        tabIndex={0}
                        onKeyDown={handleKeyDown}
                        onClick={() => analyticsEventFire(url)}
                    >
                        {iconUrl && url &&
                        <a
                            href={url}
                            className='utility-icon__link'
                            tabIndex={-1}
                            data-testid={ariaLabel}
                        >
                           <img className='utility-icon__icon' src={iconUrl} alt={ariaLabel} />
                        </a>}
                    </div>);
            case 'ACCOUNT':
                return <UserProfile icon={props.iconData} />;
            case 'CART':
                return <CartIcon>{cartIcon}</CartIcon>;
            case 'STORES':
                return <StoreLocator icon={props.iconData} headerProps={props.headerData}/>;
            default:
                return <></>;

        }
    };

    return getUtilityIcon(type ?? '');
};
