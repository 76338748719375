import React from 'react';

const CartIcon: React.FC<any> = (props) => {

    const handleKeyDown = (e: {key: string}) => {
      if (e.key === 'Enter') {
        window.location.href= '/cart';
      }
    };

  return (
    <div className='utility-icon cart-icon' data-type={'CART'} tabIndex={0} onKeyDown={handleKeyDown} data-testid='Cart'>
      {props.children}
    </div>
  );
};

export default CartIcon;