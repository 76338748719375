import React, { createContext } from 'react';
import { IMfrmHeaderViewProps } from '../../modules/mfrm-header/mfrm-header';
import { CompanyLogo } from './components/head/head-components/CompanyLogo';
import HamburgerMenuBtn from './components/head/head-components/HamburgerMenuBtn';
import MenuBar from './components/head/head-components/MenuBar';
import { SearchBar } from './components/head/head-components/SearchBar';
import { UtilityIcon } from './components/head/head-components/utility-bar/UtilityIcon';
import { HamburgerBtnProvider } from './context/HamburgerBtnContext';
import { MenuLevelProvider } from './context/MenuLevelContext';
import { MainLinkProvider } from './context/MainLinkContext';
import { MyAccountBtnProvider } from './context/MyAccountDrawerContext';

//context API for data
export const NavDataContext = createContext<any>(undefined);

export interface IUtilityIconData {
  ariaLabel: string,
  iconUrl: string,
  url: string,
  label: string,
  type: string,
  beforeLogo: boolean,
  mobile: boolean,
  desktop: boolean,
}

const MFRMHeader = (props: IMfrmHeaderViewProps) => {

  const { myAccountLinks, logoImage, logoLink, icons, myAccountIconUrl, signInUrl, signOutUrl, signUpUrl, myAccountLinkUrl } = props.config;
  let bedhubDataSource = props?.config?.bedhubDataSource;
  const customer = props.data.accountInformation?.result;
  const signInLinks = props.context.app.config;
  const {
    data: {
      bedhubHeader: { result: bedhubHeader }
    }
  } = props;

  if (bedhubHeader?.data?.ui === undefined) {
    bedhubDataSource = false;
  }

  //defining data for products, utilities and subcategories depending on if we are calling the bedhub or D365 API
  const products = bedhubDataSource ? (bedhubHeader?.data?.ui?.navbar?.products || []) : props.config.products;
  const utilities = bedhubDataSource ? (bedhubHeader?.data?.ui?.navbar?.utilities || []) : props.config.utilities;
  const bedhubIcons = bedhubDataSource && bedhubHeader?.data?.ui?.header?.actions || [];

  const d365IconData = (d365data: any): IUtilityIconData[] => {
    return d365data.map((icon: any) => {
      const iconData: IUtilityIconData = {
        ariaLabel: icon.ariaLabel ?? '',
        iconUrl: icon.iconUrl ?? '',
        url: icon.url.destinationUrl ?? '',
        label: icon.label ?? '',
        type: icon.type ?? '',
        beforeLogo: icon.beforeLogo,
        mobile: icon.mobile,
        desktop: icon.desktop
      };
      return iconData;
    });
  };

  const bedhubIconData = (bedhubIcons: any): IUtilityIconData[] => {
    return bedhubIcons.map((icon: any) => {
      const iconData: IUtilityIconData = {
        ariaLabel: icon.ariaLabel ?? '',
        iconUrl: icon.image.url ?? '',
        url: icon.url ?? '',
        label: icon.label ?? '',
        type: icon.type ?? '',
        beforeLogo: icon.beforeLogo,
        mobile: icon.mobile,
        desktop: icon.desktop
      };
      return iconData;
    });
  };
  const utilityIconData: IUtilityIconData[] = bedhubDataSource ? bedhubIconData(bedhubIcons) : d365IconData(icons);

  return (
    <div className='mfrm-header'>
      <HamburgerBtnProvider>
        <MenuLevelProvider>
          <MainLinkProvider>
            <MyAccountBtnProvider>
              <NavDataContext.Provider
                value={{
                  products,
                  utilities,
                  props,
                  myAccountLinks,
                  customer,
                  logoImage,
                  logoLink,
                  signInLinks,
                  icons,
                  bedhubHeader,
                  bedhubDataSource,
                  myAccountIconUrl,
                  signInUrl,
                  signOutUrl,
                  signUpUrl,
                  myAccountLinkUrl
                }}>
                <div className='mfrm-header__wrapper' >
                  <HamburgerMenuBtn />
                  {utilityIconData &&
                    <div className='utility-icons--before-logo'>
                      {utilityIconData?.filter((icon: IUtilityIconData) => icon?.beforeLogo)?.map((icon: IUtilityIconData) => {
                        return (
                          <UtilityIcon key={icon.ariaLabel} iconData={icon} headerData={props} />
                        );
                      })}
                    </div>}
                  <CompanyLogo />
                  <SearchBar>{props.slots.search}</SearchBar>
                  {utilityIconData &&
                    <div className='utility-icons'>
                      {utilityIconData?.map((icon: IUtilityIconData) => {
                        return (
                          <UtilityIcon key={icon.ariaLabel} iconData={icon} headerData={props} />
                        );
                      })}
                    </div>}
                </div>
                <div className='mfrm-header__menu-bar-wrapper'>
                  <MenuBar />
                </div>
              </NavDataContext.Provider>
            </MyAccountBtnProvider>
          </MainLinkProvider>
        </MenuLevelProvider>
      </HamburgerBtnProvider>
    </div >
  );
};

export default MFRMHeader;
