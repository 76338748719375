import React from 'react';
import { useMenuLevelContext } from '../../../../../context/MenuLevelContext';
import { BackToMainMenu } from '../BackToMainMenu';
import { CategoryHeader } from './CategoryHeader';
import { PrimarySubcategoryMenu } from './PrimarySubcategoryMenu';
import { SecondarySubcategoryMenu } from './SecondarySubcategoryMenu';

interface IProductCategoryMenuProps {
  categoryTitle: string;
  data: any;
  changeSelectedLevel: any
}

export const ProductCategoryMenu: React.FC<IProductCategoryMenuProps> = ({
  categoryTitle,
  data,
  changeSelectedLevel
}) => {
  const [selectedLevel] = useMenuLevelContext();

  return (
    <div className='product-category-menu'>
      <div className='product-category-menu-header'>
        {selectedLevel !== 'L1' && <BackToMainMenu changeSelectedLevel={changeSelectedLevel} />}
        <CategoryHeader categoryTitle={categoryTitle} />
      </div>
      <div className='subcategory-menu-wrapper'>
        <PrimarySubcategoryMenu categories={data.L2Data?.categories} />
        <SecondarySubcategoryMenu subcategories={data.L2Data?.subCategories} />
      </div>
    </div>
  );
};
