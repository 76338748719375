import React, { useContext } from 'react';
import { NavDataContext } from '../../../../MFRMHeader';

export const MenuLogin = () => {

  const { customer, myAccountIconUrl, signInUrl, signUpUrl, signOutUrl, myAccountLinkUrl, bedhubDataSource, bedhubHeader } = useContext(NavDataContext);
  const bedhubAccount = bedhubDataSource && bedhubHeader?.data?.ui?.header?.account || [];
  const { myAccount, signIn, signOut, signUp } = bedhubAccount;

  const currentURL = window && window.location.href || undefined;
  const signInLink = bedhubDataSource && bedhubAccount.length !== 0  ? <a href={signIn?.url} aria-label={signIn?.ariaLabel} data-testid='SignIn'>{signIn?.label}</a> : <a href={signInUrl?.linkUrl?.destinationUrl} data-testid='SignIn'>{signInUrl?.linkText}</a>;
  const signUpLink = bedhubDataSource && bedhubAccount.length !== 0  ? <a href={signUp?.url} aria-label={signUp?.ariaLabel} data-testid='SignUp'>{signUp?.label}</a> : <a href={signUpUrl?.linkUrl?.destinationUrl} data-testid='SignUp'>{signUpUrl?.linkText}</a>;
  const signOutLink = bedhubDataSource && bedhubAccount.length !== 0  ? <a href={currentURL && `${signOut?.url}%3Freturl=${currentURL}`} aria-label={signOut?.ariaLabel} data-testid='SignOut'>{signOut?.label}</a> : <a href={currentURL ? `${signOutUrl?.linkUrl?.destinationUrl}%3Freturl=${currentURL}` : signOutUrl?.linkUrl?.destinationUrl} data-testid='SignOut'>{signOutUrl?.linkText}</a>;
  const myAccountLink = bedhubDataSource && bedhubAccount.length !== 0  ? <a href={myAccount?.url} aria-label={myAccount?.ariaLabel} data-testid='MyAccount'>{myAccount?.label}</a> : <a href={myAccountLinkUrl?.linkUrl?.destinationUrl} data-testid='MyAccount'>{myAccountLinkUrl?.linkText}</a>;

  return (
    <>
      <div className='navbar-drawer_body_login-section'>
        <div className='navbar-drawer_body_login-section_icon_wrapper'>
          <img
            src={myAccountIconUrl}
            alt={'Account Profile Icon'}
            aria-label={'Account Profile Icon'}
            width='24px'
            height='24px' />
        </div>
        <p className={`navbar-drawer_body_login-section_text utility-icons__user-profile__account-drawer ${customer ? 'inactive' : 'active'}`}> Hello! {signInLink} or {signUpLink} </p>
        <p className={`navbar-drawer_body_login-section_text utility-icons__user-profile__account-drawer ${customer ? 'active' : 'inactive'}`}> {myAccountLink}{signOutLink} </p>
      </div>
    </>
  );
};

