import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Drawer from "mfcl/Drawer";
import StoreSelector from './../../../store-selector';
import { trapFocus } from '../../../../util/trap-focus';

export const StoreLocator: React.FC<any> = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { ariaLabel, iconUrl, mobile, type } = props.icon;

  const onHandleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };
  const onHandleToggleDrawer = (isDrawerOpen: boolean) => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter' && !isDrawerOpen) {
      onHandleToggleDrawer(isDrawerOpen);
    }
  };

  useEffect(() => {
    trapFocus('.store-selector-drawer__title', '.store-selector-drawer__body');
  }, [isDrawerOpen]);

  return (
    <>
      <div key={ariaLabel} data-type={type ?? 'generic'} className={classnames(mobile ? `utility-icon` : `utility-icon utility-icon--mobile-hide`)} tabIndex={0} onKeyDown={handleKeyDown}>
     { iconUrl && <button onClick={() => onHandleToggleDrawer(isDrawerOpen)} className='utility-icon__link store' tabIndex={-1} data-testid={ariaLabel} >
         <img className='utility-icon__icon store-icon' src={iconUrl} alt={ariaLabel} />
        </button>}
        <Drawer
          isOpen={isDrawerOpen}
          onClose={onHandleCloseDrawer}
          position={'left'}
          className="store-selector-drawer"
          headerClassName="store-selector-drawer__header"
          bodyClassName="store-selector-drawer__body"
          closeButtonClass="close-button"
          backdropClassName="store-selector-drawer__backdrop"
          close={true}
        >
          <StoreSelector headerProps={props.headerProps} onHandleCloseDrawer={onHandleCloseDrawer} />
        </Drawer>
      </div>
    </>
  );
};
