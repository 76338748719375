import React from 'react';

export const PromotionalBanner = (props: any) => {
  const { promotionalBannerImage, promotionalBannerImageUrl } = props.bannerData;
  if (promotionalBannerImage || props.bannerData.image) {
    return (
      <div className='promo-banner-wrapper'>
        <a href={promotionalBannerImageUrl?.destinationUrl || props?.bannerData.url} className='promo-banner-link' data-testid='PromoBannerinDrawer'>
          <img
            src={promotionalBannerImage?.src || props?.bannerData?.image?.url}
            alt={promotionalBannerImage?.altText}
            className='promo-banner-img'
          />
        </a>
      </div>
    );
  }
  return null;
};
