import React, { useContext } from 'react';
import { useHamburgerBtnContext } from '../../../context/HamburgerBtnContext';
import { useMenuLevelContext } from '../../../context/MenuLevelContext';
import { useMainLinkContext } from '../../../context/MainLinkContext';
import { NavDataContext } from '../../../MFRMHeader';

const MenuBar: React.FC<any> = () =>  {

  const [, setSelectedLevel] = useMenuLevelContext();
  const [, setSelectedLink] = useMainLinkContext();
  const [, setNavDrawerOpen] = useHamburgerBtnContext();
  const { products, bedhubDataSource } = useContext(NavDataContext);

  //opens L2 if subcategory exists, otherwise directs to url onclick
  const handleItemClick = (level: string) => {
    products.filter((product: any) => product.label === level)
    .map((item:any) => {
      //logic if calling bedhub api
      if (bedhubDataSource){
        //logic for path to url in bedhub data
        if (item.__typename === 'UIProductMenuLink'){
          if (item.action){
            window.location.href = item.action.url;
          } else {
            window.location.href =item.url;
          }
        } else {
          //if has sub category opens L2 drawer with this logic
          setNavDrawerOpen(true);
          setSelectedLevel(level);
          setSelectedLink(level);
        }
      //logic if calling d365 api- using config
      } else {
        if(item.productMenuLink){
          window.location.href = item.url.destinationUrl;
        } else {
          setNavDrawerOpen(true);
          setSelectedLevel(level);
          setSelectedLink(level);
        }
      }
    });
  };


//mapping through products that are not callout links and returning titles and data for menu bar
  const productTitles = products
    .filter((navitem:any) => !navitem.callout)
    .map((navitem:any) => {
      const label = bedhubDataSource ? <span dangerouslySetInnerHTML={{ __html: navitem?.html ?? navitem?.label }} />
                    : navitem?.richTextLabel ? <span dangerouslySetInnerHTML={{ __html: navitem?.richTextLabel }} /> : navitem?.label ;
       return (
       <a
          className='menu-bar-link'
          onClick={() => handleItemClick(navitem.label)}
          key={navitem.label}
          aria-label={navitem.label}
          tabIndex={0}
          data-testid={navitem.label}
          onKeyDown={(e: {key: string, preventDefault: () => void}) => {
            if (e.key === 'Enter') {
              handleItemClick(navitem.label);
              e.preventDefault();
            }
          }}
        >
           {label}
        </a>
        );
});

  //mapping through projects and returning callout links
  const calloutLinks = products
    .filter((navitem:any) => navitem.callout === true)
    .map((navitem:any) => {
      const label = bedhubDataSource ? <span dangerouslySetInnerHTML={{ __html: navitem?.html ?? navitem?.label }} />
                    : navitem?.richTextLabel ? <span dangerouslySetInnerHTML={{ __html: navitem?.richTextLabel }} /> : navitem?.label ;
        return (
          <a
          className='menu-bar__prominent-link'
          onClick={() => handleItemClick(navitem.label)}
          key={navitem.label}
          aria-label={navitem.label}
          tabIndex={0}
          data-testid={navitem.label}
          onKeyDown={(e: {key: string, preventDefault: () => void}) => {
            if (e.key === 'Enter') {
              handleItemClick(navitem.label);
              e.preventDefault();
            }
          }}
        >
            {label}
        </a>
        );
});

  return (
      <>
        <div className='menu-bar'>
          {products ? productTitles : null}
        </div>
        <div className='menu-bar__prominent-links-wrapper'>
          <div className='menu-bar__prominent-links'>
            {products ? calloutLinks : null}
          </div>
        </div>
      </>
    );
};

export default MenuBar;
