const charCount = (str: string, charsLimit: number) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(str)) {
        return str;
    }
    if (str?.length > charsLimit) {
        return str.substring(0, charsLimit);
    }
    return str;
};

export default charCount;
