import React, { useContext } from 'react';
import { NavDataContext } from '../../../../../MFRMHeader';
interface ICategoryHeaderProps {
  categoryTitle: string;
}

export const CategoryHeader: React.FC<ICategoryHeaderProps> = ({ categoryTitle }) => {
  const { products, bedhubDataSource } = useContext(NavDataContext);

  const shopAllButton = products.filter((label:any) => categoryTitle === label.label).map((btn:any) => {


    if (btn && btn.action) {
    return(
      <a href={bedhubDataSource ? btn.action?.url : btn.action.url.destinationUrl} key={btn.label} tabIndex={-1}>
        <button
            className='shop-all-btn'
            aria-label={btn.action?.ariaLabel}
            tabIndex={-1}
            data-testid='ShopAllButton'
        >
          {btn.action?.label}
        </button>
      </a>
    );
  } else {
    return null;
  }
  });

  return (
    <div className={`${categoryTitle?.length >20 ? 'category-header category-header-column': 'category-header'}`}>
      <p className="category-title">
        {categoryTitle}
      </p>
      <div className='shop-all-container' tabIndex={0}
        onKeyDown={(e: {key: string}) => {
          if (e.key === 'Enter') {
            const activeSubMenu = document.querySelector('.submenu-wrapper.active');
            const activeShopAllButton = activeSubMenu?.getElementsByClassName('shop-all-btn')[0];
            if(activeShopAllButton) {
              (activeShopAllButton as HTMLElement).click();
            }
          }
        }}
      >
        {shopAllButton}
      </div>
    </div>
  );
};
