export function trapFocus(
  firstQuerySelector: string,
  secondQuerySelector: string,
  thirdQuerySelector?: string
) {
    const firstFocusableElement = document.querySelector(firstQuerySelector);
    const focusableElements = 'a';
    const drawer = document.querySelector(secondQuerySelector)
      ? document.querySelector(secondQuerySelector)
      : (thirdQuerySelector !== undefined ? document.querySelector(thirdQuerySelector) : null);

    if(drawer !== null) {
      const focusableContent = drawer?.querySelectorAll(focusableElements);
      const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

      document.addEventListener('keydown', function(e) {
        const isTabPressed = e.key === 'Tab';

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) { // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
              (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
              e.preventDefault();
          }
        } else { // if tab key is pressed
          if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
              (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
              e.preventDefault();
          }
        }
      });
    }

    if(firstFocusableElement !== null){
      (firstFocusableElement as HTMLElement).focus();
    }
  }
