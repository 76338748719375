import React from 'react';
import Drawer from "mfcl/Drawer";
import L1Wrapper from './L1/L1Wrapper';
import { useHamburgerBtnContext } from '../../../context/HamburgerBtnContext';
import { MenuHeader } from './L1/MenuHeader';
import { useMenuLevelContext } from '../../../context/MenuLevelContext';

const MenuWrapper: React.FC<any> = () => {
    //drawer state from context
    const [navDrawerOpen, setNavDrawerOpen] = useHamburgerBtnContext();
    const [, setSelectedLevel] = useMenuLevelContext();

    const closeNavDrawer = () => {
        setNavDrawerOpen(false);
        setSelectedLevel('L1');
    };

    return (
        <>
            <Drawer
                isOpen={navDrawerOpen}
                onClose={closeNavDrawer}
                position={'left'}
                close={true}
                className="navbar-drawer_width"
                headerClassName='navbar-drawer_header_hide-mfcl-header'
            >
                <MenuHeader />
                <div className='navbar-drawer_body'>
                    {/* logic here for which wrapper is rendered */}
                    <L1Wrapper />
                </div>
            </Drawer>
        </>
    );
};

export default MenuWrapper;