import React, { useEffect, useState } from 'react';
// import Cookies from 'universal-cookie';
import getSociData, { GetSociDataInput } from '../../../../actions/get-soci-data.action';
import Search from './components/Search';
import Stores from './components/Stores';
import ShowAllStores from './components/showAllStores';
import Close from 'mfcl/Icons/Close';
import Cookies from 'universal-cookie';
import { getZipCode, getGeoLocationZipCode } from '../../util/store-locator';
import { StoreSelectorStateInput } from '../../../../data-actions/store-selector-state-data-action';

export type storeLocatorCookie = {
    name: string;
    value: string | undefined;
};

const StoreSelector: React.FC<any> = (props) => {
    const cookies = new Cookies();
    const zipCode = cookies.get('zip_code');
    const [locations, setLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(zipCode || '');
    const [isError, setIsError] = useState(false);
    const [defaultStore, setDefaultStore] = useState({});
    const { storeLocatorTitle, lookupRadius, maxNoOfLocations, enableShowAllStores, showAllStoresExternalLink, errorLocationsText } = props.headerProps?.config;
    useEffect(() => {
        sociLocationsWithDefault(lookupRadius, maxNoOfLocations);
    }, []);

    useEffect(() => {
        getSociLocations(lookupRadius, maxNoOfLocations, zipCode);
    }, [defaultStore]);

    const getSociLocations = (searchRadius: number, noOfLocations: number, searchTerm: string) => {
        try {
            setIsLoading(true);
            void getSociData(new GetSociDataInput(searchRadius, noOfLocations, searchTerm), props.headerProps.context.actionContext).then((data: any) => {
                if (data) {
                    const sociAvailableStores = data.response.collection && data.response.collection.filter((store: { opening_status: string; }) => store?.opening_status !== 'permanently_closed') || [];
                    const sortedLocations = sortSociLocations(sociAvailableStores);
                    setLocations(sortedLocations);
                    _setCookie({ name: 'zip_code', value: searchTerm }, 24 * 365);
                    setIsLoading(false);
                    setIsError(false);
                }
            });
        } catch (error) {
            setIsLoading(false);
            console.error('Soci API error', Error);
        }
    };

    const sociLocationsWithDefault = (searchRadius: number, noOfLocations: number) => {
        const { context: { actionContext } } = props.headerProps;
        const zipCode = getZipCode(actionContext) || '';
        setSearchTerm(zipCode);
        const selectedStore = localStorage.getItem('_mfrm__prefferedStore_') ?? undefined;
        try {
            setIsLoading(true);
            void getSociData(new GetSociDataInput(searchRadius, noOfLocations, searchTerm), props.headerProps.context.actionContext).then((data: any) => {
                if (data) {
                    const sociAvailableStores = data.response.collection && data.response.collection.filter((store: { opening_status: string; }) => store?.opening_status !== 'permanently_closed') || [];
                    const sortedLocations = sortSociLocations(sociAvailableStores);
                    setLocations(sortedLocations);
                    _setCookie({ name: 'zip_code', value: searchTerm }, 24 * 365);
                    setIsLoading(false);
                    setIsError(false);
                    if (!selectedStore) {
                        setPreferredStore(sociAvailableStores[0]);
                    }
                }
            });
        } catch (error) {
            setIsLoading(false);
            console.error('Soci API error', Error);
        }
    };

    const onGetCurrentLocations = () => {
        const { context: { actionContext } } = props.headerProps;
        const zipCode = getGeoLocationZipCode(actionContext) || '';
        setSearchTerm(zipCode);
        getSociLocations(lookupRadius, maxNoOfLocations, zipCode);
    };

    const _setCookie = (cookie: storeLocatorCookie, deltaHoursFromToday: number): void => {
        const { cookies } = props.headerProps.context.request;
        const now = new Date(Date.now());
        now.setHours(now.getHours() + deltaHoursFromToday);

        cookies.set(cookie.name, cookie.value, {
            expires: deltaHoursFromToday === 0 || deltaHoursFromToday === undefined ? 0 : now,
            isEssential: true
        } as {});
    };

    const setPreferredStore = (store: any) => {
        if (!store) {
            return;
        }
        _setCookie({ name: 'preferredStoreState', value: store?.state || '' }, 24 * 365);
        const { context: { actionContext } } = props.headerProps;
        const zip_Code = searchTerm ? searchTerm : getZipCode(actionContext);
        // On setting preferred store, current search zipCode will be used as delivery zipCode
        _setCookie({ name: 'zip_code', value: zip_Code || '' }, 24 * 365);
        // update the state to trigger zipcode change in product-compare
        const projectMetaSoci = store?.project_meta ? JSON.parse(store?.project_meta) : '';
        const sociStore = projectMetaSoci ? projectMetaSoci['Corporate ID'] : '';
        const sociStoreID = sociStore && (sociStore?.length > 6 ? sociStore : `000000${sociStore}`?.slice(-6)) || '';
        // @ts-ignore
        const selectedStore = localStorage.getItem('_mfrm__prefferedStore_') ? JSON.parse(localStorage.getItem('_mfrm__prefferedStore_')) : null;
        localStorage.setItem('_mfrm__prefferedStore_', JSON.stringify({ ...store, OrgUnitNumber: sociStoreID, Zip: store.postalcode }));
        actionContext.update(new StoreSelectorStateInput(), {
            sociPreferredStore: { ...store, OrgUnitNumber: sociStoreID, Zip: store.postalcode }
        });
        setDefaultStore(JSON.stringify({ ...store, OrgUnitNumber: sociStoreID, Zip: store.postalcode }));
        const pageURL = window && window.location.pathname;
        if (selectedStore && sociStoreID !== selectedStore?.StoreId) {
            // @ts-ignore
            if ('utag' in window) {
                // @ts-ignore
                utag.link({
                    event: 'store-locator-slideout-change-my-store',
                    event_category: 'store locator slideout',
                    event_action: 'change my store',
                    event_label: `from: ${pageURL}`,
                    event_noninteraction: 'false'
                });
            }
        }
        if (sociStoreID && selectedStore === null) {
            // @ts-ignore
            if ('utag' in window) {
                // @ts-ignore
                utag.link({
                    event: 'store-locator-slideout-make-my-store',
                    event_category: 'store locator slideout',
                    event_action: 'make my store',
                    event_label: `from: ${pageURL}`,
                    event_noninteraction: 'false'
                });
            }
        }

    };

    const onHandleSetPreferredStore = (store: any) => {
        if (!store) {
            return;
        }
        const isFranchise = JSON.parse(store?.project_meta).Division === 'Franchise' || false;
        if (isFranchise) {
            return;
        }
        setPreferredStore(store);
        props.onHandleCloseDrawer();
    };

    const removePreferredStore = async () => {
        const {
            context: {
                actionContext: {
                    requestContext: { cookies }
                }
            },
            data: {
                storeSelectorStateManager: { result: storeSelectorStateManager }
                // storeSelectorState: { result: storeSelectorState }
            }
        } = props?.headerProps;
        const { context: { actionContext } } = props?.headerProps;
        actionContext?.update(new StoreSelectorStateInput(), {
            sociPreferredStore: {}
        });

        localStorage.removeItem('_mfrm__prefferedStore_');
        localStorage.removeItem('_msdyn365__preferredStore_');
        await storeSelectorStateManager.clearPreferredStore(cookies?.isConsentGiven());
        const zip_Code = searchTerm ? searchTerm : getZipCode(actionContext);
        getSociLocations(lookupRadius, maxNoOfLocations, zip_Code);

    };

    const getPreferredStoreId = () => {
        const preferredStore = localStorage.getItem('_mfrm__prefferedStore_') || null;
        const parsedPreferredStore = JSON.parse(preferredStore!);
        return parsedPreferredStore?.OrgUnitNumber;
    };

    const sortSociLocations = (stores: any) => {
        const sociStores = stores.reduce((acc: string, store: { name: string; }) => {
            const preferredStore = localStorage?.getItem('_mfrm__prefferedStore_') || null;
            const parsedPreferredStore = JSON.parse(preferredStore!);
            if (parsedPreferredStore?.name === store.name) {
                return [store, ...acc];
            }
            return [...acc, store];
        }, []);
        return sociStores;
    };

    return (
        <>
            <div className="header" >
                {storeLocatorTitle && <h4 className="store-selector-drawer__title" tabIndex={0}>{storeLocatorTitle}</h4>}
                <button onClick={() => props.onHandleCloseDrawer()} className='close-btn'>
                    <Close width="16" height="16" stroke="#2d2926" strokeWidth="2" />
                </button>
            </div>
            <Search
                searchTerm={searchTerm}
                onGetSociLocations={getSociLocations}
                setSearchTerm={setSearchTerm}
                lookupRadius={lookupRadius}
                maxNoOfLocations={maxNoOfLocations}
                onHandleCurrentLocations={onGetCurrentLocations}
                isError={isError}
                setIsError={setIsError}
            />
            <Stores
                locations={locations || []}
                isLoading={isLoading}
                errorLocationsText={errorLocationsText}
                setPreferredStore={onHandleSetPreferredStore}
                removePreferredStore={removePreferredStore}
                preferredStoreId={getPreferredStoreId}
            />
            <ShowAllStores
                enableShowAllStores={enableShowAllStores}
                showAllStoresExternalLink={showAllStoresExternalLink}
            />
        </>
    );
};

export default StoreSelector;
