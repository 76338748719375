import React from 'react';
import { useHamburgerBtnContext } from '../../../../context/HamburgerBtnContext';
import { useMenuLevelContext } from '../../../../context/MenuLevelContext';
import { CompanyLogo } from '../../../head/head-components/CompanyLogo';

export const MenuHeader = () => {
  const [, setNavDrawerOpen] = useHamburgerBtnContext();
  const [, setSelectedLevel] = useMenuLevelContext();
  const closeDrawer = () => {
      setNavDrawerOpen(false);
      setSelectedLevel('L1');
  };
  const handleKeyDown = (e: {key: string, preventDefault: () => void}) => {
    if (e.key === 'Enter') {
      closeDrawer();
      e.preventDefault();
    }
  };

  return (
    <header className='navbar-drawer_header_container'>
        <div className='navbar-drawer_header_close' tabIndex={0}>
          <div className='navbar-drawer_header_close_container' tabIndex={0} onClick={closeDrawer} onKeyPress={handleKeyDown} data-testid='Menu Close Button'>
            <img src='https://images-us-prod.cms.dynamics365commerce.ms/cms/api/fknscsckgq/imageFileData/MAgHqi?pubver=1' alt='close' className='navbar-drawer_header_close_icon'/>
          </div>
        </div>
        <div className='navbar-drawer_header_logo'>
            <span className='navbar-drawer_header_logo'><CompanyLogo /></span>
        </div>
    </header>
  );
};
