import React, { useContext } from 'react';
import { NavDataContext } from '../../../../../MFRMHeader';
import { MfrmBadge } from '../../L1/badge';

export const SecondarySubcategoryMenu: React.FC<any> = ({ subcategories }) => {

  const { bedhubDataSource } = useContext(NavDataContext);

  // const subcategoryData = bedhubDataSource ? subcategories : subcategories.productMenuSubCategory;

  return (
    <>
      {/* <div>SecondarySubcategoryMenu</div> */}
      {
        subcategories && (bedhubDataSource ? subcategories : subcategories.productMenuSubCategory)?.map((subcategory: any) => {
          return (
            <div className="subcategory-menu" key={subcategory.label}>
              <div className="subcategory-title">
                <p>{subcategory.label}</p>
              </div>
              {
                subcategory.items && subcategory.items.map((item: any) => (
                    <div key={item.label} className='subcategory-secondary-menu-container'>
                        <a
                            href={bedhubDataSource  ? item.url : item.url.destinationUrl}
                            key={item.label}
                            data-testid={item.label}
                            className="subcategory-secondary-menu-item">
                            {
                            subcategory.label.toLowerCase() === 'shopping guides'
                            ?
                                <img
                                src="https://files-us-prod.cms.commerce.dynamics.com/cms/api/fknscsckgq/binary/MAgWZl"
                                width={24}
                                height={24}
                                alt="document icon"
                                className='subcategory-secondary-menu-item-icon'
                                />
                            : null
                            }
                            {item.label}
                        </a>
                        {item.badge && item.badge.label && <div className='subcategory-badge-wrapper'><MfrmBadge badgeData={item.badge} /></div> || null}
                    </div>
                ))
              }
            </div>
          );
        })
      }
    </>
  );
};
