import React from 'react';
import Badge from 'mfcl/Badge';
import charCount from '../../../../util/char-count';

export const MfrmBadge: React.FC<any> = (props) => {
    const { label, type } = props.badgeData;
    const badgeLabel = charCount(label, 15);
    const getBadgeBgColor = (type: string): string => {
        switch (type) {
            case 'NEW':
            case 'NEW_CAT':
            case 'NEW_SUBCAT':
                return 'rgba(224, 250, 242, 0.5)';
            case 'SALE':
            case 'SALE_CAT':
            case 'SALE_SUBCAT':
                return 'rgba(253, 229, 230, 0.5)';
            case 'URGENCY':
            case 'URGENCY_CAT':
            case 'URGENCY_SUBCAT':
                return 'rgba(252, 204, 11, 0.25)';
            case 'CONVERSATIONAL':
            case 'CONVERSATIONAL_CAT':
            case 'CONVERSATIONAL_SUBCAT':
                return '#F2F5FF';
            default:
                return 'transparent';

        }
    };

    return (
        <Badge
            data-testid={badgeLabel}
            type="primary"
            style={{
                height: '24px',
                backgroundColor: getBadgeBgColor(type),
                color: 'black',
                border: 'none',
                borderRadius: '5px',
                padding: '4px 8px',
                textTransform: 'Capitalize',
                fontWeight: '500',
                minWidth: 'auto',
                alignSelf: 'center',
                lineHeight: '16px',
                whiteSpace: 'nowrap'
            }}>
            {badgeLabel}
        </Badge>
    );
};