import React, { useContext, useEffect } from 'react';
import { MfrmBadge } from './badge';
// import { UtilityMenu } from './UtilityMenu';
import { L2Wrapper } from '../L2/L2Wrapper';
import { useMenuLevelContext } from '../../../../context/MenuLevelContext';
import { useMainLinkContext } from '../../../../context/MainLinkContext';
import { NavDataContext } from '../../../../MFRMHeader';
import { MenuLogin } from './MenuLogin';
import { trapFocus } from '../../../../util/trap-focus';
import charCount from '../../../../util/char-count';
import { useHamburgerBtnContext } from '../../../../context/HamburgerBtnContext';

const L1Wrapper: React.FC<any> = () => {
    const [selectedLevel, setSelectedLevel] = useMenuLevelContext();
     const [, setNavDrawerOpen] = useHamburgerBtnContext();
    const [, setSelectedLink] = useMainLinkContext();
    const { products, utilities, bedhubDataSource } = useContext(NavDataContext);
    const subCategories = bedhubDataSource ? products.subcategories : products.subCategories;
    const changeSelectedLevel = (level: string) => {
        return setSelectedLevel(level);
    };

    const handleL1ItemClick = (level: string, e: { preventDefault: () => void }) => {
        if (level !== 'L1') {
            e.preventDefault();
        }
        products
            .filter((product: any) => product.label === level, subCategories)
            .map((item: any) => {
                //logic if calling bedhub api
                if (bedhubDataSource) {
                    //logic for path to url in bedhub data
                    if (item.__typename === 'UIProductMenuLink') {
                        if (item.action) {
                            window.location.href = item.action.url;
                        } else {
                            window.location.href = item.url;
                        }
                    } else {
                        //if has sub category opens L2 drawer with this logic
                        setSelectedLevel(level);
                        setSelectedLink(level);
                    }
                    //logic if calling d365 api
                } else {
                    if (item.productMenuLink) {
                        window.location.href = item.url.destinationUrl;
                    } else {
                        setSelectedLevel(level);
                        setSelectedLink(level);
                    }
                }
            });
    };

    const subMenuData = products
        .map((category: any) => {
            if (!category.callout) {
                return {
                    category: category.label,
                    promotionalbannerData: category.promotionalBanner || category.promotion || null,
                    L2Data: {
                        categories: category.categories,
                        subCategories: bedhubDataSource ? category.subcategories : category.subCategories
                    }
                };
            }
            return null;
        })
        .filter((category: any) => category !== null); // flatten categories and subcategories

    const mattressMatcher =
        products &&
        products
            .filter((item: any) => item.callout === true)
            .map((item: any, id: number) => {
                const label = bedhubDataSource ? charCount(item?.html ?? item?.label, 22) : charCount(item?.richTextLabel ?? item?.label, 22);
                return (
                    <div key={item.label} className='navbar-drawer_body_link_main_item'>
                        <a
                            className='navbar-drawer_body_link_prominent'
                            tabIndex={0}
                            data-testid={item.label}
                            onClick={e => handleL1ItemClick(item.label, e)}
                            onKeyDown={(e: { key: string; preventDefault: () => void }) => {
                                if (e.key === 'Enter') {
                                    handleL1ItemClick(item.label, e);
                                }
                            }}
                        >
                            {<span dangerouslySetInnerHTML={{ __html: label }} />}
                        </a>
                        {(item.badge && item.badge.label && <MfrmBadge badgeData={item.badge} />) || null}
                    </div>
                );
            });

    const mainLinks: any =
        products &&
        products
            .filter((item: any) => !item.callout)
            .map((item: any, id: number) => {
                const removedSpacesAndSpecialText = item.label.replace(/[^A-Z0-9]/gi, '_');
                const label = bedhubDataSource ? charCount(item?.html ?? item?.label, 22) : charCount(item?.richTextLabel ?? item?.label, 22);
                return (
                    <div
                        id={`navbar-drawer_body_link_main_item-${removedSpacesAndSpecialText}_div`}
                        className='navbar-drawer_body_link_main_item'
                        tabIndex={-1}
                        key={id}
                        data-testid={item.label}
                        onClick={e => handleL1ItemClick(item.label, e)}
                        onKeyDown={(e: { key: string; preventDefault: () => void }) => {
                            if (e.key === 'Enter') {
                                handleL1ItemClick(item.label, e);
                            }
                        }}
                    >
                        <a id={`navbar-drawer_body_link_main_item-${removedSpacesAndSpecialText}_a`} tabIndex={0}>
                        { <span dangerouslySetInnerHTML={{ __html: label }} />}
                        </a>
                        {(item.badge && item.badge.label && <MfrmBadge badgeData={item.badge} />) || null}
                    </div>
                );
            });

    //call back to cancel utag click on chatwidget
    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        item: any
    ) => {
        event.preventDefault();
        var callBacks = {
            CHAT: () => simulateChatClick(item)
        };
        if (item.type in callBacks) {
            callBacks[item.type]();
        } else {
            window.location.href = bedhubDataSource ? item.url : item.url.destinationUrl;
            callUsAnalytics(item);
        }
        setNavDrawerOpen(false);
    };

    // simulates a click on chat to open chat widget and utag
    const chatWidget = document.querySelector('button.uiButton.helpButtonEnabled');

    function simulateChatClick(item: any) {
        if (item.type === 'CHAT' && chatWidget) {
            if ('utag' in window) {
                // @ts-ignore
                utag.link({
                    event: 'chat-widget-initiated',
                    event_category: 'chat',
                    event_noninteraction: 'false',
                    event_type: 'chat_click',
                    event_action: 'click from Top Nav Menu',
                    event_label: 'chat engagement'
                });
            }
            chatWidget.dispatchEvent(new Event('click'));
        } else {
            console.error('nav click chat widget not working');
        }
    }

    function callUsAnalytics(item: any) {
        if (bedhubDataSource ? item.url.includes('tel') : item.url.destinationUrl.includes('tel')) {
            if ('utag' in window) {
                //@ts-ignore
                utag.link({
                    event: 'call-engagement',
                    event_category: 'call',
                    event_noninteraction: 'false',
                    event_type: 'call_engagement',
                    event_action: 'header click',
                    event_label: 'call engagement'
                });
                window.location.href = bedhubDataSource ? item.url : item.url.destinationUrl;
            }
        }
        window.location.href = bedhubDataSource ? item.url : item.url.destinationUrl;
    }

    const helperLinks =
        utilities &&
        utilities
            .filter((item: any) => item.label !== '')
            .map((item: any) => (
                <a
                    key={item.ariaLabel}
                    onClick={e => handleClick(e, item)}
                    className='navbar-drawer_body_link_helper'
                    tabIndex={0}
                    data-testid={item.label}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleClick(e, item);
                        }
                    }}
                >
                    {item.label}
                </a>
            ));

    useEffect(() => {
        trapFocus('.navbar-drawer_header_close', '.submenu-wrapper.active');
    }, [selectedLevel]);

    return (
        <nav>
            <div className='navbar-drawer_body_link_container'>
                <div className={`navbar-drawer_body_link_wrapper ${selectedLevel === 'L1' ? 'active' : 'inactive'}`}>
                    <MenuLogin />
                    <div className='navbar-drawer_body_link_main_container'>
                        {mattressMatcher}
                        {mainLinks}
                    </div>
                    <div className='navbar-drawer_body_link_secondary_container'>{helperLinks}</div>
                </div>
            </div>
            <div className='nav-L2-link-container'>
                <div className={`L2 L2-wrapper ${selectedLevel !== 'L1' ? 'active' : 'inactive'}`}>
                    <L2Wrapper changeSelectedLevel={changeSelectedLevel} subMenuData={subMenuData} />
                </div>
            </div>
        </nav>
    );
};

export default L1Wrapper;
