import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useMyAccountBtnContext } from '../../../../context/MyAccountDrawerContext';
import MyAccountDrawer from '../my-account/MyAccountDrawer';
import { trapFocus } from '../../../../util/trap-focus';

export const UserProfile: React.FC<any> = (props) => {
  const [myAccountDrawerOpen, setMyAccountDrawerOpen] = useMyAccountBtnContext();
  const { ariaLabel, iconUrl, mobile, type, url } = props.icon;


  const openAccountDrawer = () => {
    setMyAccountDrawerOpen(true);
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      openAccountDrawer();
    }
  };

  useEffect(() => {
    trapFocus('.navbar-account_link-container', '.navbar-account_body');
  }, [myAccountDrawerOpen]);

  return (
    <>
      <div >
        <div key={ariaLabel} data-type={type ?? 'generic'} className={classnames(mobile ? `utility-icon` : `utility-icon utility-icon--mobile-hide`)} tabIndex={0} onKeyDown={handleKeyDown}>
         {iconUrl && url && <a href={url} className='utility-icon__link' tabIndex={-1} data-testid='MyAccount'>
           <img className='utility-icon__icon' src={iconUrl} alt={ariaLabel} />
          </a>}
        </div>
      </div>

      <MyAccountDrawer />
    </>
  );
};
