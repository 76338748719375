import React, { useContext } from 'react';
import { NavDataContext } from '../../../../MFRMHeader';
import { useMyAccountBtnContext } from '../../../../context/MyAccountDrawerContext';

const AccountForm = () => {
    const { myAccountLinks, customer, signInLinks } = useContext(NavDataContext);
    const [, setMyAccountDrawerOpen] = useMyAccountBtnContext();
    const closeDrawer = () => {
        setMyAccountDrawerOpen(false);
    };
    const handleKeyDown = (e: {key: string}) => {
        if (e.key === 'Enter') {
            closeDrawer();
        }
      };
    const mapAndFilterLinks = (label:string) => myAccountLinks
        .filter((item: { label: string; }) => item.label === label)
        .map((accountItem: any) => (
                <a
                    key={accountItem.label}
                    tabIndex={0} className='navbar-account_body-link'
                    href={accountItem.linkUrl?.destinationUrl}
                    data-testid={accountItem.linkText}
                >
                    {accountItem.linkText}
                </a>
        ));

    const goToAccountLink = mapAndFilterLinks('my account');
    const orderHistoryLink = mapAndFilterLinks('My Order History');
    const signInLink = <a className='navbar-account_link' tabIndex={0} href={signInLinks.signInUrl} data-testid='SignIn'>Sign In</a>;
    const signOutLink =<a className='navbar-account_link' tabIndex={0} href={signInLinks.signOutUrl} data-testid='SignOut'>Sign Out</a>;
    const signUpLink = <a className='navbar-account_body-link' tabIndex={0} href={signInLinks.signUpUrl} data-testid='SignUp'>Create Account</a>;

    if (myAccountLinks) {
        return (
            <div className='navbar-account_wrapper'>
                <div className={`${customer ? 'inactive' : 'active'}`}>
                    <div className='navbar-account_header'>
                        <div className='navbar-account_link-container' tabIndex={0}>
                            {signInLink}
                        </div>
                        <div className='navbar-account_close' tabIndex={0} onClick={closeDrawer} onKeyPress={handleKeyDown}>
                            <img src='https://images-us-prod.cms.dynamics365commerce.ms/cms/api/fknscsckgq/imageFileData/MAgHqi?pubver=1' alt='close' className='navbar-account_close-icon'/>
                        </div>
                    </div>
                    <div className='navbar-account_body'>
                        <div className='navbar-account_body-container'>
                            {signUpLink}
                        </div>
                    </div>
                </div>
                <div className={`${customer ? 'active' : 'inactive'}`}>
                <div className='navbar-account_header'>
                        <div className='navbar-account_link-container' tabIndex={0}>
                            {signOutLink}
                        </div>
                        <div className='navbar-account_close' tabIndex={0} onClick={closeDrawer} onKeyPress={handleKeyDown}>
                            <img src='https://images-us-prod.cms.dynamics365commerce.ms/cms/api/fknscsckgq/imageFileData/MAgHqi?pubver=1' alt='close' className='navbar-account_close-icon'/>
                        </div>
                    </div>
                    <div className='navbar-account_body'>
                        <div className='navbar-account_body-container'>
                            <div className='navbar-account_link-container'>
                                {goToAccountLink}
                            </div>
                            <div className='navbar-account_link-container'>
                                {orderHistoryLink}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default AccountForm;