import React, { useState, createContext, useEffect } from "react";

//Level State Context

export type ILevel = [
    string,
    React.Dispatch<React.SetStateAction<string>>
  ];

  //hook to handle level state
  export const MenuLevelState = (newLevelValue: string): ILevel => {
    const [selectedLevel, setSelectedLevel] = useState('L1');

    useEffect(() => {
      setSelectedLevel(newLevelValue);
    }, [newLevelValue]);

    return [selectedLevel, setSelectedLevel];
  };

  //context for level state
  export const MenuLevelContext = createContext<ILevel>(undefined!);

  //providing context to rest of app
interface Props{
    children: React.ReactNode;
  }

  const MenuLevelProvider = ({children}: Props) => {
    const [selectedLevel, setSelectedLevel] = useState('L1');
    return (
      <MenuLevelContext.Provider value={[selectedLevel, setSelectedLevel]}>
          {children}
      </MenuLevelContext.Provider>
    );
  };

//enabling the use of this context provider throughout the app
  const useMenuLevelContext = () => {
    return React.useContext(MenuLevelContext);
  };

  export { MenuLevelProvider, useMenuLevelContext };