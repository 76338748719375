import React, { useState, createContext, useEffect } from "react";

//Drawer state  context

export type IDrawer = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

//custom hook to handle hamburger menu state
export const HamburgerDrawerState = (newOpenValue: boolean): IDrawer => {
  const [navDrawerOpen, setNavDrawerOpen]=useState(false);

  useEffect(() => {
    setNavDrawerOpen(newOpenValue);
  }, [newOpenValue]);

  return [navDrawerOpen, setNavDrawerOpen];
  };

//context for the hamburger menu state
export const HamburgerBtnContext= createContext<IDrawer>(undefined!);


//providing context to rest of app
interface Props{
  children: React.ReactNode;
}

const HamburgerBtnProvider = ({children}: Props) => {
  const [navDrawerOpen, setNavDrawerOpen]=HamburgerDrawerState(false);
  return (
    <HamburgerBtnContext.Provider value={[navDrawerOpen, setNavDrawerOpen]}>
        {children}
    </HamburgerBtnContext.Provider>
  );
};

//enabling the use of this context provider throughout the app
const useHamburgerBtnContext = () => {
  return React.useContext(HamburgerBtnContext);
};

export { HamburgerBtnProvider, useHamburgerBtnContext };
