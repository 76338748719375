import React from 'react';
import { useMenuLevelContext } from '../../../../context/MenuLevelContext';
import { ProductCategoryMenu } from './ProductCategoryMenu/ProductCategoryMenuWrapper';
import { PromotionalBanner } from './PromotionalBanner';

interface IL2Props {
  changeSelectedLevel: any,
  subMenuData: any
  promotionalBanner?: {}
}

export const L2Wrapper: React.FC<IL2Props> = ({ subMenuData, changeSelectedLevel }) => {
  const [selectedLevel] = useMenuLevelContext();

  // Product Cat menu is all info pertaining to subcats in a category
  // L3s are the subcategories and their cards

  return (
    <>
      {/* <div>L2Wrapper</div> */}
      {
        subMenuData.map((subCat: any, i: number) => {
          return (
            <div key={i} className={`submenu-wrapper ${selectedLevel === subCat.category ? 'active' : 'inactive'}`}>
              <ProductCategoryMenu categoryTitle={subCat.category} data={subCat} changeSelectedLevel={changeSelectedLevel}/>
              {subCat.promotionalbannerData !== null && <PromotionalBanner bannerData={subCat.promotionalbannerData} />}
            </div>
          );
        })
      }
    </>
  );
};
