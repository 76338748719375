import React, { useContext } from 'react';
import Drawer from "mfcl/Drawer";
import { NavDataContext } from '../../../../MFRMHeader';
import { useMyAccountBtnContext } from '../../../../context/MyAccountDrawerContext';
import AccountForm from './AccountForm';

const MyAccountDrawer = () => {
    const [myAccountDrawerOpen, setMyAccountDrawerOpen] = useMyAccountBtnContext();
    const { myAccountLinks } = useContext(NavDataContext);
    const closeAccountDrawer = () => {
        setMyAccountDrawerOpen(false);
    };

    if (myAccountLinks) {
        return (
            <>
                <Drawer
                    isOpen={myAccountDrawerOpen}
                    onClose={closeAccountDrawer}
                    position={'right'}
                    close={true}
                    className="navbar-account_drawer"
                    headerClassName='navbar-drawer_header_hide-mfcl-header'
                >
                    <AccountForm />
                </Drawer>
            </>
        );
    } else {
        return null;
    }
};

export default MyAccountDrawer;