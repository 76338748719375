import React from "react";

const LocationIcon = ({ size = 16, color = '#d63426' }) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <svg
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="146.286 0 731.4 1024"
    >
        <path
            shapeRendering="geometricPrecision"
            fill={color}
            d="m 512 958.903 c -71.582 -96.914 -316.952 -440.296 -316.952 -593.189 c 0 -174.763 142.19 -316.952 316.952 -316.952 s 316.952 142.19 316.952 316.952 c 0 152.893 -245.37 496.274 -316.952 593.189 z m 0 -958.903 c -201.655 0 -365.714 164.059 -365.714 365.714 c 0 194.56 332.239 630.296 346.38 648.704 c 4.608 6.046 11.752 9.582 19.334 9.582 s 14.726 -3.535 19.334 -9.582 c 14.141 -18.408 346.38 -454.144 346.38 -648.704 c 0 -201.655 -164.059 -365.714 -365.714 -365.714 z m 0 487.619 c -67.243 0 -121.905 -54.662 -121.905 -121.905 s 54.662 -121.905 121.905 -121.905 c 67.243 0 121.905 54.662 121.905 121.905 s -54.662 121.905 -121.905 121.905 z m 0 -292.571 c -94.086 0 -170.667 76.581 -170.667 170.667 s 76.581 170.667 170.667 170.667 c 94.086 0 170.667 -76.581 170.667 -170.667 s -76.581 -170.667 -170.667 -170.667 z"
        />
    </svg>
);

export default LocationIcon;
