import React, { useState, createContext, useEffect } from "react";

//Link State Context

export type ILink = [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];

  //hook to handle level state
  export const MainLinkState = (newLinkValue: string): ILink => {
    const [selectedLink, setSelectedLink] = useState('');

    useEffect(() => {
      setSelectedLink(newLinkValue);
    }, [newLinkValue]);

    return [selectedLink, setSelectedLink];
  };

  //context for level state
  export const MainLinkContext = createContext<ILink>(undefined!);

  //providing context to rest of app
interface Props{
    children: React.ReactNode;
  }

  const MainLinkProvider = ({children}: Props) => {
    const [selectedLink, setSelectedLink] = useState('');
    return (
      <MainLinkContext.Provider value={[selectedLink, setSelectedLink]}>
          {children}
      </MainLinkContext.Provider>
    );
  };

//enabling the use of this context provider throughout the app
  const useMainLinkContext = () => {
    return React.useContext(MainLinkContext);
  };

  export { MainLinkProvider, useMainLinkContext };